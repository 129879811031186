import * as React from "react";
import { ValuePropositionBanner as ValuePropositionBannerSharedUI } from "@shared-ui/retail-storefront-value-proposition-banner";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

export const ValuePropositionBanner: React.FC<ComponentProps> = (props) => {
  const { templateComponent, context } = props;
  if (!templateComponent) {
    return null;
  }
  const isLandingView = templateComponent?.config?.view === "LANDING";
  const placementId = templateComponent?.config?.view || "";

  const vrboPartnerSiteIds = [9005044, 9006043]; // Stayz, Bookabach
  const isVrboPartnerSite = vrboPartnerSiteIds.includes(context.site.id);
  const view = isLandingView ? "ValuePropositionBannerLP" : "ValuePropositionBanner";

  return (
    <div className={isVrboPartnerSite ? "ValuePropositionBannerVrboPartnerSite" : view}>
      <ValuePropositionBannerSharedUI inputs={{ placementId }} />
    </div>
  );
};

export default ValuePropositionBanner;
